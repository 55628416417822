/**
 * Returns a formated published date.
 *
 * @param { string } date
 * @returns { string }
 */
export function formatPublishedDate(date: string) : string {
    const dateObj = new Date(date);
    return dateObj.toLocaleString('default', { month: 'short' }) + ' ' + dateObj.getDate() + ', ' + dateObj.getFullYear();
}

/**
 * Returns a formated published date.
 *
 * @param { string } text
 * @returns { number }
 */
export function generateTimeToRead(text: string) : number {
    const wpm = 225;
    const words = text.trim().split(/\s+/).length;
    return Math.ceil(words / wpm);
}