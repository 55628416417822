'use client'
import React, { useEffect, useState } from "react";
import SingleArticle from "../cards/singleArticle";
import axios from "axios";
import Link from 'next/link';
import { ChevronRightIcon } from '@heroicons/react/outline';

export default function FeaturedArticles() {

  const [featuredBlogs, setFeaturedBlogs] = useState<any>([]);

  const fetchFeaturedBlogs = async () => {
    try {
      const result = await axios.get(`${process.env.admin_url}/api/blogs?populate=*&filters[is_featured][$eq]=true&sort[0]=updatedAt%3Adesc&pagination[pageSize]=4`);
      
      setFeaturedBlogs(result?.data?.data);      
    } catch (e) {
      console.log(e);
    }
  }

  useEffect( () => {
    fetchFeaturedBlogs();
  }, [] );

  return (
    <>
      <div className="max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
        {featuredBlogs && featuredBlogs.map((item: any, index: number) => (
          <SingleArticle article={item} key={index} />
        ))}
      </div>
      <div className='mt-10 text-center	'>
        <Link href='/blogs' passHref>
            <button
              className="whitespace-nowrap inline-flex items-center justify-center px-12 py-3 border border-transparent rounded-full shadow-sm text-h3 font-montserratB text-white bg-btn-gradient"
            >
              {`See all articles`} &nbsp;
              <ChevronRightIcon
                className="h-4 w-4"
                style={{ strokeWidth: "3px" }}
                aria-hidden="true"
              />
            </button>
        </Link>
      </div>
    </>
  );
  
}

