import Image from "next/image";
import Link from "next/link";
import React from "react";
import { ChevronRightIcon } from '@heroicons/react/outline';
import { generateTimeToRead } from "../../util/util"

export default function SingleArticle(props: any) {
  const article = props.article;
  return (
    <div className="flex flex-col rounded-lg hover:shadow-lg overflow-hidden text-left p-4">
      <div className="flex-shrink-0">
        <Image
          src={process.env.admin_url + ( article?.attributes?.featured_image?.data?.attributes?.url || '' )}
          className={`h-48 w-full object-cover rounded-lg`}
          alt={article?.attributes?.title}
          height="186"
          width="376"
        />
      </div>
      <div className="flex-1 bg-white flex flex-col justify-between">
        <div className="flex-1 mt-6">
          <Link
            href={`${process?.env?.app_url}/blogs/${article?.attributes?.slug}`}
            passHref
          >
            <h3 className="text-2xl cursor-pointer font-bold font-sourcesans text-primary-500">
              {article?.attributes?.title}
            </h3>
          </Link>
        </div>
        {article?.attributes?.description && (
          <p className="mt-3 text-primary-500 text-body-mbl">
            {article?.attributes?.description.substring(0, 86)}{" "}
            {article?.attributes?.description.length >= 86 && "..."}
          </p>
        )}
        <div className="mt-4 flex items-center">
          <div className="pt-2 flex justify-between w-full">
            <div className="space-x-1">
              <Link href={`${process?.env?.app_url}/blogs/${article?.attributes?.slug}`} passHref>
                <div className="flex space-x-1 text-small text-primary-500 cursor-pointer">
                  <p>Read More</p>
                  <ChevronRightIcon
                  className="h-4 w-4 self-center"
                  style={{ strokeWidth: "3px" }}
                  aria-hidden="true"
                />
                </div>
              </Link>
            </div>
            <div className="flex-shrink-0">
              <div className="text-small font-medium text-primary-700">
              { generateTimeToRead( ( article?.attributes?.title  || '' ) + ( article?.attributes?.description || '' ) + ( article?.attributes?.content || '' ) ) } min read
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
